<template>
  <div>
    <el-row>
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header title="Бүтээгдэхүүн засах" />
        <!-- form error template -->
        <div class="form-error-container mb20" id="error-listener" ref="errorListener" v-if="errorChecker.length != 0">
          <header>Уучлаарай дутуу {{errorChecker.length}} зүйл байна</header>
          <ul class="error-list">
            <div v-for="(file, index) in errorChecker" :key="index">
              <li>{{file}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="productForm" :rules="productRules" ref="productForm" @submit.native.prevent="onPressSave('productForm')">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="name_mon" >
                        <el-input :disabled="true" v-model="productForm.name_mon" placeholder="Говь ноолууран цамц"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Англи нэр" prop="name_eng" >
                        <el-input :disabled="true" v-model="productForm.name_eng" placeholder="Gobi sweater"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item label="Бүртгэлийн нэр">
                    <el-input :disabled="true" v-model="productForm.register_name" placeholder="Барааны бүртгэлийн нэр"></el-input>
                  </el-form-item>
                  <el-form-item label="Монгол тайлбар" prop="description_mon">
                    <vue-editor :disabled="true" v-model="productForm.description_mon"></vue-editor>
                  </el-form-item>
                  <el-form-item label="Англи тайлбар" prop="description_eng">
                    <vue-editor :disabled="true" v-model="productForm.description_eng"></vue-editor>
                  </el-form-item>
                  <el-form-item    label="Категори" prop="category_id" class="position-relative">
                    <tree-selector disabled= "true" v-model="productForm.category_id" :treeData="categories" multiple width="100%" />
                    <!-- <tree-selector v-model="productForm.category_id" :options="categories" :props="defaultProps" /> -->
                  </el-form-item>
                  <el-form-item label="Үндсэн категори" prop="main_category_id" class="position-relative">
                    <el-select v-model="productForm.main_category_id" width="100%">
                      <el-option v-for="category in categories" :key="category.uniq_id" :value="category.uniq_id" :label="category.name_mon">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <!-- Price section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Үнэ</header>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Татах үнэ" prop="get_price">
                        <el-input :disabled="true" v-model="productForm.get_price" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Борлуулах үнэ" prop="price">
                        <el-input :disabled="true" v-model="productForm.price" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <div class="pb20">
                    <el-checkbox :disabled="true" v-model="productForm.sell_check">Энэ хямдралтай бүтээгдэхүүн</el-checkbox>
                  </div>
                  <div class="variants-container pane" v-if="productForm.sell_check">
                    <el-form-item label="Хямдарсан үнэ">
                      <el-input  :disabled="true" v-model="productForm.discounted_price" type="number" placeholder="0₮">
                        <template slot="append">₮</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <!-- Group order section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Групп захиалга</header>
                </div>
                <div class="panel-item">
                  <strong>24</strong> цагийн дотор <strong>10</strong> хүн <strong>20%</strong> хямдралтай авах боломжтой
                </div>
              </div>
              <!-- Variants section -->
              <div class="panel" v-if="productForm.variants.length > 0">
                <div class="panel-item">
                  <header>Бараа сонголт - {{productForm.variants.length}} төрөл</header>
                </div>
                <div class="panel-item">
                    <el-table
                      :data="productForm.variants"
                      @cell-click="onPressItem"
                      style="width: 100%">
                      <el-table-column
                        label="Нэр"
                        :show-overflow-tooltip="true"
                        prop="variant_name">
                      </el-table-column>
                      <el-table-column
                        label="Төлөв"
                        width="140"
                        prop="variant_status">
                      </el-table-column>
                      <el-table-column
                        label="Зарах үнэ"
                        width="140"
                        prop="variant_sellPrice">
                      </el-table-column>
                    </el-table>
                </div>
              </div>
              <!-- Inventory section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Агуулах</header>
                  <!-- <el-form-item label="Barcode (ISBN, UPC, GTIN, etc.)" prop="barcode">
                    <el-input v-model="productForm.barcode" type="text"></el-input>
                  </el-form-item> -->
                </div>
                <div class="panel-item">
                  <div class="mb20">
                    <el-checkbox :disabled="true" v-model="productForm.is_physical">Энэ нь биетэй бүтээгдэхүүн</el-checkbox>
                  </div>
                </div>
                <div class="panel-item" v-if="productForm.is_physical">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                        label="Жин">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input :disabled="true" v-model="productSize.Weight" type="number">
                              <el-select v-model="productSize.weightSuffix" slot="append">
                                <el-option label="kg" value="kg"></el-option>
                                <el-option label="gram" value="g"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="Урт">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input :disabled="true" v-model="productSize.Length" type="number">
                              <el-select v-model="productSize.lengthSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                        label="Өргөн">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input :disabled="true"  v-model="productSize.Width" type="number">
                              <el-select v-model="productSize.widthSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="Өндөр">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input :disabled="true" v-model="productSize.Height" type="number">
                              <el-select v-model="productSize.heightSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                          </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <!-- right sidebar -->
            <el-col :span="8">
              <!-- Product status section -->
              <div class="panel">
                <div class="panel-item">
                  <el-form-item label="Төлөв" prop="status">
                    <el-select :disabled="true" v-model="productForm.status" class="block">
                      <el-option
                        v-for="item in statusOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <!-- Brand section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Нийлүүлэгч & Брэнд</header>
                  <el-form-item label="Нийлүүлэгч" prop="supplier_id">
                    <el-select
                      v-model="productForm.supplier_id"
                      :disabled="true"
                      @change="onChangeSupplier"
                      filterable
                      remote
                      placeholder="Таван богд"
                      :remote-method="filterSupplier"
                      :loading="supplierLoading">
                      <el-option
                        v-for="item in suggestedSupplier"
                        :key="item.supplier_id"
                        :label="item.supplier_monName"
                        :value="item.supplier_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Брэнд" prop="brand_id" v-if="productForm.supplier_id != ''">
                    <loader :active="isLoadBrand" />
                    <el-select
                      v-model="productForm.brand_id"
                      :disabled="true"
                      filterable
                      class="block">
                      <el-option
                        v-for="item in brandOptions"
                        :key="item.brand_id"
                        :label="item.brand_monName"
                        :value="item.brand_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <!-- Properties section -->
              <div class="panel">
                <div class="panel-item pb20">
                  <header>Шинж чанар</header>
                </div>
                <div class="panel-item" v-for="(property, index) in productForm.properties" :key="index">
                  <div class="property-item">
                    <el-row :gutter="10" type="flex" align="middle">
                      <el-col :span="18">
                        <div>
                          <strong>{{property.name}}</strong>
                        </div>
                        <div>
                          <span class="text-color-secondary">{{property.value}}</span>
                        </div>
                      </el-col>
                      <el-col :span="6" class="text-right">
                        <i class="el-icon-delete cursor-pointer" @click="removeProperty(property)"></i>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Өөрчлөлтүүдийг хадгалах уу"
      :visible.sync="confirmLeaveForm"
      width="50%">
      <span>Хэрвээ та устгах товч дарвал таны хийсэн бүх өөрчлөлт устагдахыг анхаарна уу.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10">Үргэлжлүүлэх</el-button>
        <el-button type="danger" @click="onPressClose">Устгах</el-button>
      </span>
    </el-dialog>
    <!-- Image view dialog -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <loader :active="isLoading" is-full-screen />
  </div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import services from '../../../helpers/services'
import { handlers } from '../../../helpers/custom'
import CustomHeader from '../../../components/common/customHeader'
import TreeSelector from '../../../components/common/treeSelector'
import Loader from '../../../components/common/loader'

const PRODUCT_DATA = {
  name_mon: '',
  name_eng: '',
  register_name: '',
  description_mon: '',
  description_eng: '',
  price: '',
  get_price: '',
  sell_check: false,
  discounted_price: '',
  image: [],
  package_image: [],
  sku: '',
  is_physical: true,
  brand_id: '',
  supplier_id: '',
  barcode: '',
  status: 'active',
  category_id: '',
  main_category_id: '',
  variants: [],
  properties: [],
  physical_size: {
    Weight: '',
    Length: '',
    Width: '',
    Height: ''
  }
}
export default {
  name: 'addProduct',
  components: {
    CustomHeader,
    TreeSelector,
    Loader,
    VueEditor
  },
  data () {
    return {
      property: {
        name: '',
        value: ''
      },
      visibleSuggest: false,
      categoryResult: [],
      searchValue: '',
      defaultProps: {
        parent: 'parent',
        value: 'uniq_id',
        label: 'name_mon',
        children: 'childrens'
      },
      isLoading: false,
      isUploading: false,
      isUploadingPackage: false,
      isLoadBrand: false,
      confirmLeaveForm: false,
      dialogImageUrl: '',
      isNewForm: true,
      dialogVisible: false,
      categories: [],
      tempImages: [],
      tempImagesPackage: [],
      options: [],
      statusOption: [],
      suggestedSupplier: [],
      supplierLoading: false,
      brandOptions: [],
      productSize: {
        Weight: '',
        Length: '',
        Width: '',
        Height: '',
        weightSuffix: 'kg',
        heightSuffix: 'cm',
        widthSuffix: 'cm',
        lengthSuffix: 'cm'
      },
      backUpProductData: PRODUCT_DATA,
      productForm: PRODUCT_DATA,
      productRules: {
        name_mon: [
          { required: true, message: 'Монгол нэрээ оруулна уу', trigger: 'change' }
        ],
        name_eng: [
          { required: true, message: 'Англи нэрээ оруулна уу', trigger: 'change' }
        ],
        description_mon: [
          { required: true, message: 'Монгол тайлбараа оруулна уу', trigger: 'change' }
        ],
        description_eng: [
          { required: true, message: 'Англи тайлбараа оруулна уу', trigger: 'change' }
        ],
        category_id: [
          { required: true, message: 'Категорио сонгоно уу', trigger: 'blur' }
        ],
        main_category_id: [
          { required: true, message: 'Категорио сонгоно уу', trigger: 'blur' }
        ],
        price: [
          { required: true, message: 'Үнээ оруулна уу', trigger: 'change' }
        ],
        get_price: [
          { required: true, message: 'Татах үнээ оруулна уу', trigger: 'change' }
        ],
        status: [
          { required: true, message: 'Төлөвөө сонгоно уу', trigger: 'change' }
        ],
        supplier_id: [
          { required: true, message: 'Нийлүүлэгчээ сонгоно уу', trigger: 'change' }
        ],
        brand_id: [
          { required: true, message: 'Брэндээ сонгоно уу', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.params.product_id) {
      this.getProductData(this.$route.params.product_id)
      this.getStatusOptions()
      this.getOptions()
    }
  },
  computed: {
    errorChecker () {
      var errors = []
      if (!this.isNewForm) {
        if (this.tempImages.length === 0) {
          errors.push('Зураг оруулна уу')
        }
        if (this.productForm.properties.length === 0) {
          errors.push('Шинж чанар оруулна уу')
        }
      }
      return errors
    }
  },
  methods: {
    onPressItem (data) {
      this.$router.push({ name: 'stockDetail', params: { variant_id: data.variant_id } })
    },
    getProductData (productId) {
      this.isLoading = true
      services.getOneProduct(productId).then((response) => {
        if (response.status === 'success' && response.data.length !== 0) {
          const data = response.data[0]
          const categoryId = JSON.parse(JSON.stringify(data.category_id))
          data.category_id = ''
          this.productForm = data
          this.backUpProductData = JSON.parse(JSON.stringify(data))
          if (Object.prototype.hasOwnProperty.call(data, 'physical_size')) {
            this.productSize = {
              Weight: parseFloat(data.physical_size.Weight),
              Length: parseFloat(data.physical_size.Length),
              Width: parseFloat(data.physical_size.Width),
              Height: parseFloat(data.physical_size.Height),
              weightSuffix: data.physical_size.Weight.split(' ')[1],
              heightSuffix: data.physical_size.Height.split(' ')[1],
              widthSuffix: data.physical_size.Width.split(' ')[1],
              lengthSuffix: data.physical_size.Length.split(' ')[1]
            }
          } else {
            data.physical_size = {}
          }
          this.getCategory(categoryId)
          this.getSuppliers(data.supplier_id, data.brand_id)
          this.setImages(data.image)
          this.setPackageImages(data.package_image)
          this.isLoading = false
        } else {
          this.onPressClose()
        }
      })
    },
    setImages (image) {
      if (image) {
        image.forEach(element => {
          this.tempImages.push({
            url: element
          })
        })
      }
    },

    setPackageImages (image) {
      if (image) {
        image.forEach(element => {
          this.tempImagesPackage.push({
            url: element
          })
        })
      }
    },

    handleRemove (file, type, fileList) {
      var tempId = 0
      if (type === 'image') {
        this.tempImages.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImages.splice(tempId, 1)
      } else if (type === 'package') {
        this.tempImagesPackage.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesPackage.splice(tempId, 1)
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getFile (file, type, fileList) {
      handlers.checkImageSize(file, type === 'image', 1020, 1020).then(() => {
        if (type === 'image') {
          this.isUploading = true
        } else if (type === 'package') {
          this.isUploadingPackage = true
        }
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            if (type === 'image') {
              this.isUploading = false
            } else if (type === 'package') {
              this.isUploadingPackage = false
            }
            if (response.status === 'success') {
              if (type === 'image') {
                this.tempImages.push({
                  url: response.url
                })
              } else if (type === 'package') {
                this.tempImagesPackage.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },
    getCategory (categoryId) {
      services.getCategoriesAsTree().then(data => {
        data.forEach(element => {
          element.label = element.name_mon
          element.id = element.uniq_id
          if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
            element.childrens.forEach(el => {
              el.label = el.name_mon
              el.id = el.uniq_id
              if (Object.prototype.hasOwnProperty.call(el, 'childrens')) {
                el.childrens.forEach(el1 => {
                  el1.label = el1.name_mon
                  el1.id = el1.uniq_id
                  if (Object.prototype.hasOwnProperty.call(el1, 'childrens')) {
                    el1.childrens.forEach(el2 => {
                      el2.label = el2.name_mon
                      el2.id = el2.uniq_id
                    })
                    el1.children = el1.childrens
                  }
                })
                el.children = el.childrens
              }
            })
            element.children = element.childrens
          }
        })
        this.categories = data
        this.productForm.category_id = categoryId
      })
    },
    getStatusOptions () {
      this.statusOption = [{
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }]
    },
    getOptions () {
      this.options = [{
        value: 'Хэмжээ',
        label: 'Хэмжээ'
      }, {
        value: 'Өнгө',
        label: 'Өнгө'
      }, {
        value: 'Материал',
        label: 'Материал'
      }]
    },
    getBrands (supplier, brand) {
      this.isLoadBrand = true
      services.getBrandsBySupplierId(supplier).then(response => {
        this.isLoadBrand = false
        if (response.status === 'success') {
          this.brandOptions = response.data.datas
          this.productForm.brand_id = brand
        }
      })
    },
    onChangeSupplier (id) {
      this.isLoadBrand = true
      services.getBrandsBySupplierId(id).then(response => {
        this.isLoadBrand = false
        if (response.status === 'success') {
          this.brandOptions = response.data.datas
          this.productForm.supplier_id = id
          this.productForm.brand_id = ''
        }
      })
    },
    getSuppliers (supplier, brand) {
      services.getAllSuppliers().then(response => {
        this.suggestedSupplier = response.datas
        if (supplier && supplier !== '') {
          this.productForm.supplier_id = supplier
          if (brand && brand !== '') {
            this.getBrands(supplier, brand)
          } else {
            this.productForm.brand_id = ''
            this.brandOptions = []
          }
        } else {
          this.$message.error('Уучлаарай нийлүүлэгчийн мэдээлэл олдсонгүй!')
        }
      })
    },
    filterSupplier (val) {
      if (val.length > 1) {
        services.searchSupplier(val).then(response => {
          if (response.datas && Array.isArray(response.datas)) {
            this.suggestedSupplier = response.datas
          } else {
            this.suggestedSupplier = []
          }
        })
      }
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    addSuffix (data) {
      var subData = {}
      subData.Weight = `${data.Weight} ${data.weightSuffix}`
      subData.Height = `${data.Height} ${data.heightSuffix}`
      subData.Width = `${data.Width} ${data.widthSuffix}`
      subData.Length = `${data.Length} ${data.lengthSuffix}`
      return subData
    },
    onPressSave (formName) {
      this.isNewForm = false
      this.productForm.sku = this.productForm.sku === '' ? this.skuGenerator : this.productForm.sku
      this.productForm.physical_size = this.addSuffix(this.productSize)
      this.productForm.price = parseFloat(this.productForm.price)
      this.productForm.get_price = parseFloat(this.productForm.get_price)
      this.productForm.discounted_price = parseFloat(this.productForm.discounted_price)
      var tempImages = []
      this.tempImages.forEach(element => {
        tempImages.push(element.url)
      })
      this.productForm.image = tempImages
      var tempImagesPackage = []
      this.tempImagesPackage.forEach(element => {
        tempImagesPackage.push(element.url)
      })
      this.productForm.package_image = tempImagesPackage
      this.$refs[formName].validate((valid) => {
        if (valid && this.errorChecker.length === 0 && !this.isUploading) {
          var tempData = {
            productId: this.productForm.productId,
            product: this.productForm
          }
          this.isLoading = true
          services.updateProduct(this.productForm.productId, tempData).then(data => {
            this.isLoading = false
            this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
            if (data.status === 'success') {
              this.$router.go(-1)
            }
          })
        } else {
          window.scrollTo(0, 0)
          return false
        }
      })
    },
    removeProperty (property) {
      this.productForm.properties = this.productForm.properties.filter(item => property !== item)
    },
    addProperty (e) {
      if (this.property.name && this.property.value) {
        this.productForm.properties = [...this.productForm.properties, { name: this.property.name, value: this.property.value }]
        this.property.name = ''
        this.property.value = ''
      }
    },
    onPressClose () {
      this.confirmLeaveForm = false
      this.$router.go(-1)
    }
  }
}
</script>
